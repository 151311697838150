var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"pay-history__content"},[_c('my-table',{staticClass:"text-9",attrs:{"data":_vm.tableItems,"loading":_vm.loading,"cols":_vm.cols},scopedSlots:_vm._u([{key:"amount",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$currencySign)+" "+_vm._s(value)+" ")])]}},{key:"pay_type",fn:function(ref){
var value = ref.value;
return [(value === 'alipay')?_c('fa',{attrs:{"icon":['fab', 'alipay']}}):_vm._e(),(value === 'wechat')?_c('fa',{attrs:{"icon":['fab', 'weixin']}}):_vm._e()]}},{key:"created_at",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(new Date(value).toLocaleString()))])]}},{key:"status_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.status_name))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }