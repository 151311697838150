<template>
  <section>
    <div class="pay-history__content">
      <my-table class="text-9" :data="tableItems" :loading="loading" :cols="cols">
        <template v-slot:amount="{ value }">
          <span> {{ $currencySign }} {{ value }} </span>
        </template>

        <template v-slot:pay_type="{ value }">
          <fa v-if="value === 'alipay'" :icon="['fab', 'alipay']" />
          <fa v-if="value === 'wechat'" :icon="['fab', 'weixin']" />
          <!-- <span> {{ getPayName(value) }} </span> -->
        </template>

        <template v-slot:created_at="{ value }">
          <span>{{ new Date(value).toLocaleString() }}</span>
        </template>

        <template v-slot:status_name="{ item }">
          <span class="ml-2">{{ item.status_name }}</span>
        </template>
      </my-table>
    </div>
  </section>
</template>

<script>
import { MerchantService } from '@/services/api'
import { errorParser } from '@/utils' 

const COLS = [
  // { label: '单号', value: 'order_no', fixed: '210' },
  { label: '金额', value: 'amount', fixed: '150' },
  { label: '时间', value: 'created_at' },
  { label: '状态', value: 'status_name', fixed: '150' },
]

export default {
  name: 'Transactions',
  data() {
    return {
      loading: false,
      tableItems: [],
      cols: COLS, 
    }
  },
  created() {},
  mounted() {
    this.loadWithdrawHistory()
  },
  methods: {
    toast(message, type = 'warning') {
      this.$bvToast.toast(message, {
        title: message,
        toaster: 'b-toaster-bottom-right',
        solid: true,
        variant: type,
      })
    },
    async loadWithdrawHistory() {
      this.loading = true
      try {
        const { data } = await MerchantService.getWithdrawHistory()
        this.tableItems = data
      } catch (e) {
        this.toast(errorParser(e.response), 'danger')
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../styles/index';
</style>
