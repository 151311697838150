<template>
  <section class="container">
    <div class="pointer go-back mt-5 mb-4 pl-1 text-secondary" @click="$router.push('/dashboard')">
      <fa :icon="['fa', 'chevron-left']" />
      <span class="pl-2">返回</span>
    </div>
    <div class="mt-4">
      <b-modal id="api" centered hide-header ok-title="保存" cancel-title="取消" @ok="save">
        <div class="p-3">
          <h4>{{ types[modifyType] }}</h4>
          <b-form-input v-model="modifyInput" class="api-input mt-4" type="text" required placeholder="请输入新API" />
        </div>
      </b-modal>

      <div class="card d-flex flex-row align-items-center px-5">
        <b-avatar :src="user.info.avatar_url" rounded="lg" size="120" />
        <div class="profile__name">
          <div class="text-white mb-1">
            {{ user.info.name }}
          </div>
          <div class="text-11 text-secondary">
            <span>提现余额：</span>
            <span class="text-primary">{{ $currencySign }} {{ withdrawBalance }}</span>
          </div>
        </div>
        <div class="d-flex">
          <!-- <btn class="btn btn-outline-secondary px-5 py-3" @click="$bvModal.show('topup')">登出</btn> -->
          <btn class="btn btn-outline-primary px-5 py-3" @click="$bvModal.show('withdraw-modal')">提现</btn>
        </div>
      </div>

      <div class="flex mt-2">
        <div class="card col-2 mr-2 text-secondary" style="min-height: 318px">
          <div
            class="d-flex justify-content-start align-items-center py-4 pointer pl-4"
            @click="tab = 'merchant'"
            :class="{ 'text-white': tab === 'merchant' }"
          >
            <fa :icon="['far', 'user']" />
            <span class="ml-2">商户设置</span>
          </div>
          <div
            class="d-flex justify-content-start align-items-center py-4 pointer pl-4"
            @click="tab = 'history'"
            :class="{ 'text-white': tab === 'history' }"
          >
            <fa :icon="['fa', 'yen-sign']" />
            <span class="ml-2">提现历史</span>
          </div>
        </div>

        <div class="card" v-if="tab === 'merchant'">
          <div class="p-4">
            <div class="d-flex align-items-center">
              <span class="text-white">饰品回调API</span>
            </div>
            <div class="d-flex align-items-center mt-2">
              <search-input :value="callbackUrl" style="flex:1" read-only hide-icon width="100%" />
              <btn class="btn-outline-secondary px-5 ml-4" size="sm" @click="modifyCallbackApi">
                修改
              </btn>
            </div>
          </div>
          <div class="p-4">
            <div class="d-flex align-items-center">
              <span class="text-white">查询用户ID账号API</span>
            </div>
            <div class="d-flex align-items-center mt-2">
              <search-input :value="fetchUserUrl" style="flex:1" read-only hide-icon width="100%" />
              <btn class="btn-outline-secondary px-5 ml-4" size="sm" @click="modifyFetchUserApi">
                修改
              </btn>
            </div>
          </div>
          <div class="p-4">
            <div class="d-flex align-items-center">
              <span class="text-white">接受交易报价API</span>
            </div>
            <div class="d-flex align-items-center mt-2">
              <search-input :value="offerAcceptUrl" style="flex:1" read-only hide-icon width="100%" />
              <btn class="btn-outline-secondary px-5 ml-4" size="sm" @click="modifyOfferAcceptApi">
                修改
              </btn>
            </div>
          </div>
        </div>

        <div class="card" v-if="tab === 'history'">
          <withdraw-history />
        </div>
      </div>
    </div>

    <b-modal id="withdraw-modal" centered hide-header hide-footer>
      <div class="p-3">
        <h5 class="text-white mt-2">
          请输入提现金额
        </h5>
        <div style="position:relative">
          <!-- <fa :icon="['fa', 'yen-sign']" style="position:absolute;left:10px;top:11px" />
          <b-form-input
            v-model="withdrawAmount"
            class="trade-url-input mt-4 text-white"
            style="padding-left:30px"
            type="number"
            min="100"
            required
            placeholder="请输入金额"
          /> -->
          <i-input v-model="withdrawAmount" type="number" :min="100" class="w-full mt-5">
            提现金额
          </i-input>
        </div>
      </div>
      <div class="flex justify-center mt-4">
        <btn class="btn-secondary" @click="$bvModal.hide('withdraw-modal')">取消</btn>
        <btn class="btn-primary" @click="withdraw" :disabled="loadingWithdraw">提现</btn>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { MerchantService } from '@/services/api'
import { errorParser } from '@/utils'
import WithdrawHistory from './WithdrawHistory'

const TYPES = {
  callback_url: '饰品回调API',
  fetch_merchant_user_url: '查询用户ID账号API',
  offer_accept_url: '接受交易报价API',
}

export default {
  name: 'Profile',
  components: { WithdrawHistory },
  data() {
    return {
      callback_url: null,
      fetch_merchant_user_url: null,
      offer_accept_url: null,
      modifyType: 'callback_url',
      modifyInput: '',
      types: TYPES,
      withdrawBalance: '',
      withdrawAmount: 100,
      tab: 'merchant',
      loadingWithdraw: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
    callbackUrl() {
      if (!this.user.info || !this.user.info.callback_url) return '未设置'
      return this.user.info.callback_url
    },
    fetchUserUrl() {
      if (!this.user.info || !this.user.info.fetch_merchant_user_url) return '未设置'
      return this.user.info.fetch_merchant_user_url
    },
    offerAcceptUrl() {
      if (!this.user.info || !this.user.info.offer_accept_url) return '未设置'
      return this.user.info.offer_accept_url
    },
  },
  created() {
    this.loadWithdrawBalance()
  },
  mounted() {
    if (this.defaultTab) {
      this.tab = this.defaultTab
    }
  },
  methods: {
    toast(message, type = 'warning') {
      let msg = message
      if (type === 'danger') {
        msg = errorParser(message).message
      }
      this.$bvToast.toast(msg, {
        title: msg,
        toaster: 'b-toaster-bottom-right',
        solid: true,
        variant: type,
      })
    },
    modifyCallbackApi() {
      this.modifyInput = this.user.info.callback_url
      this.modifyType = 'callback_url'
      this.$bvModal.show('api')
    },
    modifyFetchUserApi() {
      this.modifyInput = this.user.info.fetch_merchant_user_url
      this.modifyType = 'fetch_merchant_user_url'
      this.$bvModal.show('api')
    },
    modifyOfferAcceptApi() {
      this.modifyInput = this.user.info.offer_accept_url
      this.modifyType = 'offer_accept_url'
      this.$bvModal.show('api')
    },
    async save() {
      try {
        const settings = {
          [this.modifyType]: this.modifyInput,
        }
        await MerchantService.saveSetting(settings)
        this.toast('API设置成功', 'success')
      } catch (e) {
        this.toast(e.response, 'danger')
      }
      this.$store.dispatch('LOAD_USER')
    },
    async loadWithdrawBalance() {
      try {
        const { data } = await MerchantService.getWithdrawBalance()
        this.withdrawBalance = data.balance
      } catch (e) {}
    },
    async withdraw() {
      this.loadingWithdraw = true
      try {
        const { data } = await MerchantService.withdraw(this.withdrawAmount)
        this.toast(data.message, 'success')
        this.$bvModal.hide('withdraw-modal')
        this.loadWithdrawBalance()
      } catch (e) {
        this.toast(e.response, 'danger')
      } finally {
        this.loadingWithdraw = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../../styles/style.scss';
.api-input {
  background-color: transparent;
  border-color: #434365;
}
.set_trade_url {
  cursor: pointer;
}
.profile {
  &__icon {
    color: #39c4e5;
    font-size: 20px;
  }
  &__steam-id {
    font-size: 14px;
  }
  &__steam-id {
    font-size: 14px;
  }
  &__api {
    &-label {
      font-size: 14px;
    }
    user-select: none;
    padding: 0 2em;
    flex: 0 0 400px;
  }
  &__name {
    border-right: solid 1px $glutterColor;
    height: 125px;
    width: 100%;
    display: flex;
    flex: 1 1 50%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    padding: 0 2em;
    font-size: 1.4em;
    color: white;
  }

  &__card {
    height: 180px;
  }
}
</style>
